<template>
    <div class="main-page">
        <template v-if="showHeader">
            <section class="page-section mb-3" >
                <div class="container">
                    <div class="grid justify-content-between">
                        <div class="col-12 md:col-6 lg:col-5 " >
                            <div class="" >
                                <div class="flex align-items-center ">
                                    <div>
                                        <div class="text-2xl text-primary font-bold">Edit Lu Codigodeunidades</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </template>
        <section class="page-section " >
            <div class="container">
                <div class="grid ">
                    <div class="md:col-9 sm:col-12 comp-grid" >
                        <div :class="{ 'card': !isSubPage }" class="">
                            <div >
                                <template v-if="pageReady">
                                    <div class="grid">
                                        <div class="col-12">
                                            <form ref="observer"  tag="form" @submit.prevent="submitForm()">
                                                <!--[form-content-start]-->
                                                <div class="grid">
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Clave *
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlclave" v-model.trim="formData.clave"  label="Clave" type="text" placeholder="Enter Clave"      
                                                                class=" w-full" :class="getErrorClass('clave')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('clave')" class="p-error">{{ getFieldError('clave') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Descript *
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrldescript" v-model.trim="formData.descript"  label="Descript" type="text" placeholder="Enter Descript"      
                                                                class=" w-full" :class="getErrorClass('descript')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('descript')" class="p-error">{{ getFieldError('descript') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Saci 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlsaci" v-model.trim="formData.saci"  label="Saci" type="text" placeholder="Enter Saci"      
                                                                class=" w-full" :class="getErrorClass('saci')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('saci')" class="p-error">{{ getFieldError('saci') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Uom 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrluom" v-model.trim="formData.uom"  label="Uom" type="text" placeholder="Enter Uom"      
                                                                class=" w-full" :class="getErrorClass('uom')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('uom')" class="p-error">{{ getFieldError('uom') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Edemsa 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrledemsa" v-model.trim="formData.edemsa"  label="Edemsa" type="text" placeholder="Enter Edemsa"      
                                                                class=" w-full" :class="getErrorClass('edemsa')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('edemsa')" class="p-error">{{ getFieldError('edemsa') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!--[form-content-end]-->
                                                <div v-if="showSubmitButton" class="text-center my-3">
                                                    <Button type="submit" label="Update" icon="pi pi-send" :loading="saving" />
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </template>
                                <template v-if="loading">
                                    <div class="p-3 text-center">
                                        <ProgressSpinner style="width:50px;height:50px" />
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script setup>
	import {  computed,  reactive, toRefs, onMounted } from 'vue';
	import useVuelidate from '@vuelidate/core';
	import { required } from '@/services/validators';
	import { useApp } from '@/composables/app.js';
	import { useEditPage } from '@/composables/editpage.js';
	const props = defineProps({
		id: [String, Number],
		pageName: {
			type: String,
			default: 'lu_codigodeunidades',
		},
		routeName: {
			type: String,
			default: 'lu_codigodeunidadesedit',
		},
		pagePath: {
			type : String,
			default : 'lu_codigodeunidades/edit',
		},
		apiPath: {
			type: String,
			default: 'lu_codigodeunidades/edit',
		},
		submitButtonLabel: {
			type: String,
			default: "Update",
		},
		msgAfterUpdate: {
			type: String,
			default: "Record updated successfully",
		},
		showHeader: {
			type: Boolean,
			default: true,
		},
		showSubmitButton: {
			type: Boolean,
			default: true,
		},
		redirect: {
			type : Boolean,
			default : true,
		},
		isSubPage: {
			type : Boolean,
			default : false,
		},
	});
	const app = useApp();
	const formDefaultValues = {
		clave: "0", 
		descript: "", 
		saci: "", 
		uom: "", 
		edemsa: "", 
	}
	const formData = reactive({ ...formDefaultValues });
	function onFormSubmited(response) {
		app.flashMsg(props.msgAfterUpdate);
		if(props.redirect) app.navigateTo(`/lu_codigodeunidades`);
	}
	const rules = computed(() => {
		return {
			clave: { required },
			descript: { required },
			saci: {  },
			uom: {  },
			edemsa: {  }
		}
	});
	const v$ = useVuelidate(rules, formData); //form validation
	const page = useEditPage(props, formData, v$, onFormSubmited);
	//page state
	const {
		submitted, // form api submitted state - Boolean
		saving, // form api submiting state - Boolean
		loading, // form data loading state - Boolean
		pageReady, // if api data loaded successfully
	} = toRefs(page.state);
	//page computed propties
	const {
		apiUrl, // page current api path
		currentRecord, // current page record  - Object
	} = page.computedProps
	//page methods
	const { 
		load, // get editable form data from api
		submitForm, // submit form data to api
		getErrorClass, // return error class if field is invalid- args(fieldname)
		getFieldError, //  get validation error message - args(fieldname)
		isFieldValid, // check if field is validated - args(fieldname)
		 // map api datasource  to Select options label-value
	} = page.methods;
	onMounted(()=>{
		const pageTitle = "Edit Lu Codigodeunidades";
		app.setPageTitle(props.routeName, pageTitle); // set browser page title
		load();
	});
</script>
<style scoped>
</style>
